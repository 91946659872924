
import { Component, Vue } from 'vue-property-decorator'
import { Info } from '../../types/diseases'
import { FileInfo } from '@/types/common'

@Component({
  filters: {
    imgListFilter (arr: Array<FileInfo>) {
      return arr.map(item => {
        return item.filePrefix + item.fileUrl
      })
    }
  }
})
export default class DiseasesDetail extends Vue {
  private tabList = ['危害及症状', '发生规律', '防治方法', '病原种类', '参考文献']
  private tabIndex = '0'
  private info: Info | {} = {}
  get diseasesId () {
    return this.$route.params.id || ''
  }

  created () {
    if (this.diseasesId) {
      this.getDetail()
    }
  }

  getDetail () {
    this.$axios.get(this.$apis.diseases.detailDiseases, {
      diseasesId: this.diseasesId
    }).then(res => {
      this.info = res
    })
  }
}
